import "./AssetCaption.style.scss";
import { useTranslation } from "react-i18next";
export interface AssetCaptionProps {
  assetType?: string;
  caption?: string;
  source_credit?: string;
  use_caption?: boolean;
}

export const AssetCaption = (props: AssetCaptionProps) => {
  const caption = props.use_caption && props.caption ? props.caption : null;
  const { t } = useTranslation();

  if (!caption && !props.source_credit) {
    return null;
  }

  return (
    <p className="component-asset-caption">
      {caption ? <span className="asset-caption">{caption}</span> : null}
      {props.source_credit ? (
        <span className="asset-credit">
          {t(`${props.assetType}`)}: {props.source_credit}
        </span>
      ) : null}
    </p>
  );
};

export default AssetCaption;
