import { ResponsiveImage } from "../components/ResponsiveImage.component";
import { ImageAssetInterface } from "@ihr-radioedit/inferno-core";
import { generateImageAssetSizes } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "../ui";
import AssetCaption from "./AssetCaption.component";
import { RESPONSIVE_IMAGE } from "../ui/constants";

export const ImageAsset = (props: ImageAssetInterface) => {
  const altText = props.title || props.alt_text || "";
  const { srcset, sizes } = generateImageAssetSizes(props.imgUrl);
  const placeholderSrc = `${placeholderImage}?ops=resize(900,506),quality(10)`;

  return (
    <figure className="component-image-asset">
      {props.counter ? <section className="image-counter">{props.counter}</section> : null}
      <section className="image-container">
        {props.url ? (
          <MagicLink to={props.url} target={props.new_tab ? "_blank" : "_self"}>
            <ResponsiveImage
              src={placeholderSrc}
              placeholder={placeholderSrc}
              alt={altText}
              srcset={srcset}
              sizes={sizes}
              initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
              initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
            />
          </MagicLink>
        ) : (
          <ResponsiveImage
            src={placeholderSrc}
            placeholder={placeholderSrc}
            alt={altText}
            srcset={srcset}
            sizes={sizes}
            initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
            initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
          />
        )}
      </section>
      <figcaption>
        <AssetCaption {...props} assetType="photo" />
      </figcaption>
    </figure>
  );
};

export default ImageAsset;
